<template>
  <div>
    <span
      v-if="label"
      class="text-black-56"
      :class="
        isRedesignLabel
          ? 'text-[18px] leading-[18px] xl:text-[20px] xl:leading-[24px] mb-6 text-new-test-black font-normal font-roboto block'
          : 'mb-1 text-smaller'
      "
      >{{ label }}</span
    >
    <div
      class="the-input relative flex items-center"
      :class="[
        { 'the-input__inverse': isInverse },
        isRedesignAuth
          ? 'h-[60px]'
          : isRedesignFooter
          ? 'h-[40px] md:h-[57px]'
          : 'h-10',
      ]"
    >
      <SvgIcon
        v-if="getIconName && btnText"
        :name="getIconName"
        class="absolute left-4 cursor-pointer"
        @click="toggleType"
      />
      <input
        :type="activeType"
        :value="modelValue"
        :placeholder="placeholder"
        class="text-small size-full rounded-lg py-3 placeholder-shown:text-[16px] placeholder-shown:leading-[19px]"
        :class="getInputClasses"
        @input="emit('update:model-value', $event.target.value)"
      />
      <SvgIcon
        v-if="getIconName && !btnText"
        :name="getIconName"
        class="absolute right-4 cursor-pointer"
        @click="toggleType"
      />
      <button
        v-if="btnText"
        class="the-input__button absolute -right-1px h-full rounded-lg px-8 font-roboto text-[8px] uppercase leading-[8px] md:text-[14px] md:leading-[17px]"
        @click.prevent="emit('on-click')"
      >
        {{ btnText }}
      </button>
    </div>
    <span v-if="error" class="text-smaller mt-1 text-ruby-red-100">
      {{ error }}
    </span>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: undefined,
  },

  type: {
    type: String,
    default: 'text',
    validate: (value) =>
      ['password', 'search', 'email', 'text'].includes(value),
  },

  btnText: {
    type: String,
    default: '',
  },

  // icon: {
  //   type: String,
  //   default: '',
  // },

  placeholder: {
    type: String,
    default: '',
  },

  label: {
    type: String,
    default: '',
  },

  isInverse: {
    type: Boolean,
    default: false,
  },

  isRedesignAuth: {
    type: Boolean,
    default: false,
  },

  isRedesignFooter: {
    type: Boolean,
    default: false,
  },

  isRedesignLabel: {
    type: Boolean,
    default: false,
  },

  // initialValue: {
  //   type: String,
  //   default: '',
  // },

  error: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:model-value', 'on-click']);

const activeType = ref(props.type);

const getIconName = computed(() => {
  let res = '';
  switch (props.type) {
    case 'search':
      res = 'search';
      break;
    case 'password':
      res = 'eye-slash';
      break;
    default:
      break;
  }
  return res;
});

const getInputClasses = computed(() => {
  let res = '';

  if (props.isRedesignAuth) return (res = 'redesign-input');

  if (props.btnText) res += ' w-5/6';

  res += props.isInverse ? ' bg-white-100' : ' bg-alice-blue-100';

  switch (props.type) {
    case 'email':
    case 'text':
      res += ' px-4';
      break;
    case 'search':
    case 'password':
      res += props.btnText ? ' pl-10 pr-4' : ' pr-10 pl-4';
      break;
    default:
  }

  return res;
});

const toggleType = () => {
  if (props.type === 'password') {
    if (activeType.value === 'password') {
      activeType.value = 'text';
    } else {
      activeType.value = 'password';
    }
  }
};
</script>

<style lang="scss">
.the-input {
  //&:hover {
  //  .the-input__button {
  //    @apply bg-blue-ryb-100 text-white-100;
  //  }
  //}

  &__button {
    @apply transition-all ease-in-out duration-300 bg-black-100 text-white-100;
  }

  &__inverse {
    & .the-input__button {
      @apply bg-new-red-dark text-white-100;
    }
  }

  .redesign-input {
    @apply border border-new-card--border rounded-lg px-6 pt-[18px] pb-5 text-[18px] leading-[22px] font-roboto;
  }
}
</style>
